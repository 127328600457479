<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo"> </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Cadastrar"
          back-button-text="Anterior"
          next-button-text="Avançar"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- personal details tab -->
          <tab-content title="" :before-change="validationFormInfo">
            <validation-observer ref="infoRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Conte-nos sobre você</h5>
                  <small class="text-muted">Cadastre seus dados</small>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Primeiro Nome" label-for="firstName">
                    <validation-provider
                      #default="{ errors }"
                      name="Primeiro Nome"
                      rules="required"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="form.firstName"
                        placeholder="Insira seu nome"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Sobrenome" label-for="lastName">
                    <validation-provider
                      #default="{ errors }"
                      name="lastName"
                      rules="required"
                    >
                      <b-form-input
                        id="sobrenome"
                        v-model="form.lastName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Insira seu Sobrenome"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="CPF" label-for="cpf">
                    <validation-provider
                      #default="{ errors }"
                      name="cpf"
                      rules="required"
                    >
                      <b-form-input
                        id="cpf"
                        v-model="form.cpf"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Insira seu CPF"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Telefone" label-for="phoneNumber">
                    <validation-provider
                      #default="{ errors }"
                      name="Telefone"
                      rules="required"
                    >
                      <cleave
                        id="phoneNumber"
                        v-model="form.phoneNumber"
                        class="form-control"
                        :raw="false"
                        :state="errors.length > 0 ? false : null"
                        :options="options.phone"
                        placeholder="Insira seu Telefone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Celular" label-for="mobilePhone">
                    <validation-provider
                      #default="{ errors }"
                      name="Celular"
                      rules="required"
                    >
                      <cleave
                        id="mobilePhone"
                        v-model="form.mobilePhone"
                        class="form-control"
                        :raw="false"
                        :state="errors.length > 0 ? false : null"
                        :options="options.phone"
                        placeholder="Insira seu Telefone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <!-- accoint details tab -->
          <tab-content title="" :before-change="validationForm">
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Conte-nos sobre você</h5>
                  <small class="text-muted"> Cadastre seus dados </small>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="john.doe@email.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Password" label-for="password">
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|min:5"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.login.password"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Confirm Password" label-for="c-password">
                    <validation-provider
                      #default="{ errors }"
                      name="Password Confirm"
                      rules="required|confirmed:Password"
                    >
                      <b-form-input
                        id="c-password"
                        v-model="form.login.passwordConfirm"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Re-type Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- address  -->
          <tab-content title="" :before-change="validationFormAddress">
            <validation-observer ref="addressRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Endereço</h5>
                  <small class="text-muted">Digite seu endereço.</small>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Endereço" label-for="address">
                    <validation-provider
                      #default="{ errors }"
                      name="Endereço"
                      rules="required"
                    >
                      <b-form-input
                        id="address"
                        v-model="form.address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Digite aqui seu endereço"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Bairro" label-for="landmark">
                    <validation-provider
                      #default="{ errors }"
                      name="Bairro"
                      rules="required"
                    >
                      <b-form-input
                        id="landmark"
                        v-model="form.neighborhood"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Digite aqui seu bairro"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="CEP" label-for="cep">
                    <validation-provider
                      #default="{ errors }"
                      name="CEP"
                      rules="required"
                    >
                      <b-form-input
                        id="cep"
                        v-model="form.postalCode"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        placeholder="658921"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Cidade" label-for="city">
                    <validation-provider
                      #default="{ errors }"
                      name="Cidade"
                      rules="required"
                    >
                      <b-form-input
                        id="city"
                        v-model="form.city"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Birmingham"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Estado" label-for="state">
                    <validation-provider
                      #default="{ errors }"
                      name="Estado"
                      rules="required"
                    >
                      <b-form-input
                        id="state"
                        v-model="form.state"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Digite aqui o seu estado"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- social link -->
          <!-- <tab-content title="" :before-change="validationFormSocial">
            <validation-observer ref="socialRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Social Links</h5>
                  <small class="text-muted">Enter Your Social Links</small>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Twitter" label-for="twitter">
                    <validation-provider
                      #default="{ errors }"
                      name="Twitter"
                      rules="required|url"
                    >
                      <b-form-input
                        id="twitter"
                        v-model="twitterUrl"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://twitter.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Facebook" label-for="facebook">
                    <validation-provider
                      #default="{ errors }"
                      name="Facebook"
                      rules="required|url"
                    >
                      <b-form-input
                        id="facebook"
                        v-model="facebookUrl"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://facebook.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Google+" label-for="google-plus">
                    <validation-provider
                      #default="{ errors }"
                      name="Google+"
                      rules="required|url"
                    >
                      <b-form-input
                        id="google-plus"
                        v-model="googleUrl"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://plus.google.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="LinkedIn" label-for="linked-in">
                    <validation-provider
                      #default="{ errors }"
                      name="LinkedIn"
                      rules="required|url"
                    >
                      <b-form-input
                        id="linked-in"
                        v-model="linkedinUrl"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://linkedin.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content> -->
        </form-wizard>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { codeIcon } from './code'
import personService from '@/services/personService'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    VuexyLogo,
    Cleave,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    TabContent,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    vSelect,
    ToastificationContent,
    BFormInvalidFeedback,
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'BR',
        },
      },
      form: {
        address: null,
        city: null,
        cpf: null,
        email: null,
        firstName: null,
        lastName: null,
        login: {
          login: null,
          password: null,
          passwordConfirm: null,
        },
        mobilePhone: null,
        neighborhood: null,
        phoneNumber: null,
        postalCode: null,
        state: null,
        isLegalPerson: 'PHYSICAL_PERSON',
      },
      sideImg: require('@/assets/images/auth/yaplay_white.png'),
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/auth/yaplay_white.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async formSubmitted() {
      personService
        .register(this.prepareRequest())
        .then(res => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Cadastro realizado com sucesso! Cheque seu email.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'login' })
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Não foi possível realizar seu cadastro',
              icon: 'TimesIcon',
              variant: 'warn',
            },
          })
        })
    },
    prepareRequest() {
      this.form.login.login = this.form.email
      return this.form
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
